@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto.woff2") format("woff2"), url("../fonts/Roboto.woff") format("woff"), url("../fonts/Roboto.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url("../fonts/Robotobold.woff2") format("woff2"), url("../fonts/Robotobold.woff") format("woff"), url("../fonts/Robotobold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html,
body {
  position: relative;
  overflow-x: hidden;
}
body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-size: 18px;
  font-weight: 400;
  font-family: Roboto, Arial, sans-serif;
  line-height: 1.3;
  color: #000;
  background-color: #fff;
}
a {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s ease;
}
a:hover {
  color: inherit;
  text-decoration: none;
}
ol,
ul {
  list-style-position: inside;
}
.wrap {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}
header.header__wrap {
  padding-top: 60px;
  padding-bottom: 40px;
  margin-bottom: 20px;
}
header .header__logo {
  display: block;
  height: 76px;
  width: 100%;
  text-align: center;
  margin-bottom: 40px;
}
header .header__logo img {
  height: 100%;
  width: auto;
}
header .header__nav {
  position: relative;
  text-align: center;
}
header .header__nav:before {
  display: block;
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: #E4A988;
  z-index: 0;
}
header .header__nav ul {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  font-weight: 700;
  background-color: #fff;
  padding: 0 10px;
}
header .header__nav li {
  margin: 0 10px;
}
header .header__nav a:hover {
  text-decoration: underline;
}
header .header__burger {
  display: none;
  position: relative;
  width: 30px;
  height: 28px;
}
header .header__burger span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: #000;
  border-radius: 2px;
  transition: all 0.3s ease;
}
header .header__burger span:nth-child(2) {
  top: 12px;
  width: 20px;
}
header .header__burger span:nth-child(3) {
  top: 24px;
}
main {
  flex: 1;
}
.news {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 0 -30px 20px;
}
.news__item {
  max-width: 360px;
  margin: 0 25px 30px;
}
.news__item:hover .news__img {
  opacity: 0.7;
}
.news__item:hover .news__title {
  text-decoration: underline;
}
.news__img {
  display: block;
  width: 100%;
  height: 200px;
  margin-bottom: 25px;
  opacity: 1;
  transition: all 0.3s ease;
}
.news__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;
}
.news__title {
  font-weight: 700;
}
.center-news {
  position: relative;
  width: 100%;
  height: 490px;
  padding-top: 90px;
  margin-bottom: 50px;
}
.center-news__bg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.center-news__meta {
  position: absolute;
  max-width: 86%;
  height: 400px;
  left: 7%;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background-color: #FAF0EA;
}
.center-news__meta .meta__img {
  flex-basis: 50%;
  height: 100%;
}
.center-news__meta .meta__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.center-news__meta .meta__text {
  flex-basis: 50%;
  padding: 55px;
}
.center-news__meta .meta__text span {
  display: block;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 40px;
}
.content {
  padding: 0 135px;
  margin-bottom: 50px;
}
.content > * {
  margin-bottom: 20px;
}
.content h1,
.content h2,
.content h3 {
  font-weight: 700;
  text-align: center;
}
.content h1 {
  font-size: 36px;
}
.content h2 {
  font-size: 24px;
}
.content h3 {
  font-size: 20px;
}
.content img.full {
  width: 100%;
}
.content img.left {
  float: left;
  margin-right: 15px;
}
.content img.right {
  float: right;
  margin-left: 15px;
}
.content img.center {
  display: block;
  max-width: 100%;
  margin: 0 auto 12px;
}
.content blockquote {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  background-color: #F5F5F5;
  padding: 70px 50px;
  font-style: italic;
}
.content blockquote:before {
  display: block;
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 120px;
  height: 100px;
  background-image: url('../img/quote-icon.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.content table {
  border-collapse: collapse;
  margin-left: auto;
  margin-right: auto;
  font-size: 12px;
}
.content table td,
.content table th {
  padding: 10px 5px;
  border: 1px solid #cccccc;
}
.content table th {
  background-color: #E5E5E5;
  font-weight: 700;
  text-align: center;
}
footer {
  width: 100%;
  background-color: #FAF0EA;
}
footer .footer__wrap {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 70px;
  padding-bottom: 60px;
}
footer .footer__logo {
  height: 80px;
  width: auto;
  margin-right: 20px;
}
footer .footer__logo img {
  height: auto;
  width: auto;
}
footer .footer__right {
  width: 100%;
  max-width: 600px;
}
footer .footer__nav {
  list-style: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: 30px;
  margin-bottom: 40px;
  border-bottom: 1px solid #E4A988;
  font-weight: 700;
}
footer .footer__nav li {
  margin-left: 30px;
}
footer .footer__nav a:hover {
  text-decoration: underline;
}
footer .footer__social {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
footer .footer__social img {
  margin-right: 15px;
  cursor: pointer;
}
footer .footer__social img:last-child {
  margin-right: 0;
}
footer .footer__copyright {
  display: none;
  flex-basis: 100%;
  font-size: 12px;
  text-align: center;
}
@media screen and (max-width: 991px) {
  .center-news__meta .meta__text {
    padding: 20px;
  }
}
@media screen and (max-width: 768px) {
  header {
    background-color: #FAF0EA;
  }
  header.header__wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 25px;
    padding-bottom: 25px;
    margin-bottom: 10px;
  }
  header .header__logo {
    width: auto;
    height: 60px;
    margin-bottom: 0;
  }
  header .header__burger {
    display: block;
  }
  header .header__burger.active span:nth-child(1) {
    width: 40px;
    transform: rotate(36deg);
    transform-origin: left;
  }
  header .header__burger.active span:nth-child(2) {
    width: 0;
  }
  header .header__burger.active span:nth-child(3) {
    width: 40px;
    transform: rotate(-36deg);
    transform-origin: left;
  }
  header .header__nav {
    position: absolute;
    top: 110px;
    right: -100%;
    padding: 45px;
    width: 100%;
    height: calc(100% - 110px);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    background-color: #FAF0EA;
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s ease-in-out;
    z-index: 999999;
  }
  header .header__nav.active {
    right: 0;
    opacity: 1;
    visibility: visible;
  }
  header .header__nav:before {
    display: none;
  }
  header .header__nav ul {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 21px;
    font-weight: 400;
    padding: 0;
    background-color: transparent;
  }
  header .header__nav ul li {
    margin: 0 0 40px 0;
  }
  .center-news {
    height: 430px;
    padding-top: 30px;
  }
  .center-news__meta {
    max-width: 94%;
    left: 3%;
  }
  .center-news__meta .meta__img {
    flex-basis: 40%;
  }
  .center-news__meta .meta__text {
    flex-basis: 60%;
  }
  .content {
    padding: 0;
  }
  .content blockquote {
    padding: 20px;
  }
  .content blockquote:before {
    width: 70px;
    height: 70px;
  }
  footer {
    background-color: #FAF0EA;
  }
  footer .footer__wrap {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  footer .footer__logo {
    display: none;
  }
  footer .footer__right {
    display: none;
  }
  footer .footer__copyright {
    display: block;
  }
}
@media screen and (max-width: 560px) {
  .center-news__meta {
    flex-direction: column;
    align-items: stretch;
  }
  .center-news__meta .meta__img {
    height: 10%;
  }
}
@media screen and (max-width: 480px) {
  .content table td {
    min-width: auto;
    padding: 8px 15px;
  }
  .content img.left {
    float: none;
    margin-right: 0;
    width: 100%;
  }
  .content img.right {
    float: none;
    margin-left: 0;
    width: 100%;
  }
  .content ul,
  .content ol {
    padding-left: 0;
  }
}
