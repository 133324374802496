@import "fonts";

@beige: #FAF0EA;
@gray: #E5E5E5;

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body {
  position: relative;
  overflow-x: hidden;
}

body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-size: 18px;
  font-weight: 400;
  font-family: Roboto, Arial, sans-serif;
  line-height: 1.3;
  color: #000;
  background-color: #fff;
}
a {
  color: inherit;
  text-decoration: none;
  transition: all .3s ease;

  &:hover {
    color: inherit;
    text-decoration: none;
  }
}
ol, ul {
  list-style-position: inside;
}

.wrap {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

header {
  &.header__wrap {
    padding-top: 60px;
    padding-bottom: 40px;
    margin-bottom: 20px;
  }
  .header {
    &__logo {
      display: block;
      height: 76px;
      width: 100%;
      text-align: center;
      margin-bottom: 40px;
      
      img {
        height: 100%;
        width: auto;
      }
    }
    &__nav {
      position: relative;
      text-align: center;

      &:before {
        display: block;
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background-color: #E4A988;
        z-index: 0;
      }
      ul {
        position: relative;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        list-style: none;
        font-weight: 700;
        background-color: #fff;
        padding: 0 10px;
      }
      li {
        margin: 0 10px;
      }
      a {
        &:hover {
          text-decoration: underline;
        }
      }
    }
    &__burger {
      display: none;
      position: relative;
      width: 30px;
      height: 28px;

      span {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 4px;
        background-color: #000;
        border-radius: 2px;
        transition: all .3s ease;

        &:nth-child(2) {
          top: 12px;
          width: 20px;
        }
        &:nth-child(3) {
          top: 24px;
        }
      }
    }
  }
}

main {
  flex: 1;
}

.news {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 0 -30px 20px;

  &__item {
    max-width: 360px;
    margin: 0 25px 30px;
    
    &:hover {
      .news {
        &__img {
          opacity: 0.7;
        }
        &__title {
          text-decoration: underline;
        }
      }
    }
  }
  &__img {
    display: block;
    width: 100%;
    height: 200px;
    margin-bottom: 25px;
    opacity: 1;
    transition: all .3s ease;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top center;
    }
  }
  &__title {
    font-weight: 700;
  }
}
.center-news {
  position: relative;
  width: 100%;
  height: 490px;
  padding-top: 90px;
  margin-bottom: 50px;

  &__bg {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &__meta {
    position: absolute;
    max-width: 86%;
    height: 400px;
    left: 7%;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    background-color: @beige;

    .meta {
      &__img {
        flex-basis: 50%;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      &__text {
        flex-basis: 50%;
        padding: 55px;

        span {
          display: block;
          font-size: 24px;
          font-weight: 700;
          margin-bottom: 40px;
        }
      }
    }
  }
}

.content {
  padding: 0 135px;
  margin-bottom: 50px;

  & > * {
    margin-bottom: 20px;
  }

  h1, h2, h3 {
    font-weight: 700;
    text-align: center;
  }
  h1 {
    font-size: 36px;
  }
  h2 {
    font-size: 24px;
  }
  h3 {
    font-size: 20px;
  }
  img {
    &.full {
      width: 100%;
    }
    &.left {
      float: left;
      margin-right: 15px;
    }
    &.right {
      float: right;
      margin-left: 15px;
    }
    &.center {
      display: block;
      max-width: 100%;
      margin: 0 auto 12px;
    }
  }
  blockquote {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    background-color: #F5F5F5;
    padding: 70px 50px;
    font-style: italic;

    &:before {
      display: block;
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      width: 120px;
      height: 100px;
      background-image: url('../img/quote-icon.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
  }

  table {
    border-collapse: collapse;
    margin-left: auto;
    margin-right: auto;
    font-size: 12px;

    td, th {
      padding: 10px 5px;
      border: 1px solid darken(@gray, 10%);
    }
    th {
      background-color: @gray;
      font-weight: 700;
      text-align: center;
    }
  }
}

footer {
  width: 100%;
  background-color: @beige;

  .footer {
    &__wrap {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding-top: 70px;
      padding-bottom: 60px;
    }
    &__logo {
      height: 80px;
      width: auto;
      margin-right: 20px;

      img {
        height: auto;
        width: auto;
      }
    }
    &__right {
      width: 100%;
      max-width: 600px;
    }
    &__nav {
      list-style: none;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-wrap: wrap;
      padding-bottom: 30px;
      margin-bottom: 40px;
      border-bottom: 1px solid #E4A988;
      font-weight: 700;

      li {
        margin-left: 30px;
      }
      a {
        &:hover {
          text-decoration: underline;
        }
      }
    }
    &__social {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      img {
        margin-right: 15px;
        cursor: pointer;

        &:last-child {
          margin-right: 0;
        }
      }
    }
    &__copyright {
      display: none;
      flex-basis: 100%;
      font-size: 12px;
      text-align: center;
    }
  }
}

@import "media";